<template>
  <div class="getPoints">
    <div class="title">
      <div class="title-l" v-if="isSuccess">积分获取成功</div>
      <div class="title-r" v-else>积分获取失败</div>
    </div>
    <div class="img">
      <img src="@/assets/img/ivon_success.png" v-if="isSuccess" alt="" /><img
        v-else
        src="@/assets/img/fail.png"
        alt=""
      />
    </div>
    <div class="pointBody" v-if="isSuccess">
      <div class="pointBody-l">
        {{ pointsData.operationType == 1 ? "+" : "-"
        }}{{ pointsData.pointCount }}
      </div>
      <div class="pointBody-r">分</div>
    </div>
    <div class="myPoint" @click="toMyPoint">我的积分</div>
  </div>
</template>

<script>
import { pointsUrl, pointsList, getPoints } from "./api.js";
import { getHashParam } from "@/utils/utils.js";
var moment = require("moment");
export default {
  name: "points",
  data() {
    return {
      isSuccess: true,
      pointsData: {
        pointCount: 0,
      },
    };
  },
  destroyed() {},
  computed: {
    communityId() {
      return this.$store.state.communityId;
    },
    userId() {
      return this.$store.state.userId;
    },
    roomId() {
      return this.$store.state.houseId;
    },
    tenantId() {
      return this.$store.state.tenantId;
    },
  },
  mounted() {
    this.addPoints();
  },
  methods: {
    toMyPoint() {
      this.$router.push({
        name: "mall",
        query: {
          fromGetpoint: 1,
        },
      });
    },
    addPoints() {
      let operationType = getHashParam("operationType");
      let settingCode = getHashParam("settingCode");
      let recordType = getHashParam("recordType");
      let params = {
        communityId: this.communityId,
        assetId: this.roomId,
        userId: this.userId,
        tenantId: this.tenantId,
        operationType,
        settingCode,
        recordType,
      };
      this.$axios
        .post(`${getPoints}`, params, {
          headers: { "Content-Type": "application/json; charset=UTF-8" },
        })
        .then((res) => {
          if (res.code === 200) {
            this.$toast("获取积分成功");
            this.isSuccess = true;
            this.pointsData = res.data;
          } else {
            let msg = res.msg || "获取积分失败";
            this.isSuccess = false;
            this.$toast(msg);
          }
        });
    },
  },
};
</script>
<style lang="less" scoped>
.getPoints {
  min-height: 100vh;
  background: #f3f4f9;
  position: relative;
  overflow: hidden;
  box-sizing: border-box;
  .title-l,
  .title-r {
    font-size: 48px;
    text-align: center;
    margin: 100px 0 0 0;
  }
  .img {
    width: 180px;
    height: 180px;
    margin: 40px auto;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .pointBody {
    display: flex;
    justify-content: center;
    align-items: center;
    .pointBody-l {
      font-size: 40px;
      margin-right: 10px;
    }
    .pointBody-r {
      font-size: 60px;
    }
  }
  .myPoint {
    border: 1px solid #f1a674;
    width: 320px;
    height: 90px;
    font-size: 40px;
    line-height: 90px;
    margin: 200px auto;
    text-align: center;
    color: #f1a674;
    border-radius: 20px;
  }
}
</style>
